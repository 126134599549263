import { hasOwnProperty } from 'lib/object.js'

/*
 * Event listening and dispatching.
 */
export function addEventListener(element, eventNames, callback, options) {
	eventNames.split(',').map(name => name.trim()).forEach(eventName => {
		element.addEventListener(eventName, callback, options)
	})
}
export { addEventListener as on }

export function removeEventListener(element, eventName, callback, options) {
	return element.removeEventListener(eventName, callback, options)
}
export { removeEventListener as off }

export function dispatchEvent(element, type, options = {}) {
	let event

	/*
	 * We default dispatched events to bubble since many of our component event
	 * listeners are attached to the document and without bubbling would never get
	 * triggered.
	 */
	if (!hasOwnProperty(options, 'bubbles')) {
		options.bubbles = true
	}

	if (type === 'click') {
		options.view = window

		event = new MouseEvent(type, options)
	} else if (type.startsWith('key')) {
		event = new KeyboardEvent(type, options)
	} else if (options.detail) {
		event = new CustomEvent(type, options)
	} else {
		event = new Event(type, options)
	}

	element.dispatchEvent(event)
}
export { dispatchEvent as dispatch }

export function preventDefaultAndStop(event) {
	event.preventDefault()
	event.stopPropagation()
	event.stopImmediatePropagation()
}
export { preventDefaultAndStop as stop }
